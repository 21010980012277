@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&family=Inconsolata:wght@200..900&display=swap');

html,
body {
	margin: 0;
	padding: 0;
	font-family: Roboto, Helvetica, Arial, sans-serif;
}
.alice-carousel .animated {
  animation-fill-mode: both;
}

.alice-carousel .animated-out {
  z-index: 1;
}

.alice-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
.alice-carousel {
  position: relative;
  width: 100%;
  margin: auto;
  direction: ltr;
}

.alice-carousel__wrapper {
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  box-sizing: border-box;
  width: 100%;
  height: auto;
}

.alice-carousel__stage {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  transform-style: flat;
  -webkit-transform-style: flat;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
.alice-carousel__stage-item {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  vertical-align: top;
  white-space: normal;
  line-height: 0;
}
.alice-carousel__stage-item * {
  line-height: normal;
  line-height: initial;
}
.alice-carousel__stage-item.__hidden {
  opacity: 0;
  overflow: hidden;
}

.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  display: inline-block;
  box-sizing: border-box;
  width: 50%;
  padding: 10px 5px;
}
.alice-carousel__prev-btn [data-area]::after,
.alice-carousel__next-btn [data-area]::after {
  position: relative;
  content: attr(data-area);
  text-transform: capitalize;
}

.alice-carousel__prev-btn {
  text-align: right;
}

.alice-carousel__prev-btn-item,
.alice-carousel__next-btn-item {
  display: inline-block;
  cursor: pointer;
  padding: 5px;
  margin: 0;
  color: #465798;
}
.alice-carousel__prev-btn-item:hover,
.alice-carousel__next-btn-item:hover {
  color: darkred;
}
.alice-carousel__prev-btn-item.__inactive,
.alice-carousel__next-btn-item.__inactive {
  opacity: 0.4;
  pointer-events: none;
}

.alice-carousel__play-btn {
  position: absolute;
  top: 30px;
  left: 20px;
  display: inline-block;
}
.alice-carousel__play-btn:hover {
  cursor: pointer;
}
.alice-carousel__play-btn-wrapper {
  position: relative;
  width: 32px;
  height: 32px;
  padding: 10px;
  border-radius: 50%;
  background-color: #fff;
}

.alice-carousel__play-btn-item {
  position: absolute;
  width: 32px;
  height: 32px;
  cursor: pointer;
  border: 0;
  outline: none;
  background: transparent;
}
.alice-carousel__play-btn-item::before, .alice-carousel__play-btn-item::after {
  position: absolute;
  pointer-events: none;
  display: block;
  width: 0;
  height: 0;
  content: "";
  transition: all 0.4s linear;
  border-width: 8px 0 8px 15px;
  border-style: solid;
  border-color: transparent;
  border-left-color: #465798;
}
.alice-carousel__play-btn-item::before {
  left: 5px;
  height: 14px;
}
.alice-carousel__play-btn-item::after {
  top: 7px;
  left: 18px;
}
.alice-carousel__play-btn-item.__pause::before, .alice-carousel__play-btn-item.__pause::after {
  height: 30px;
  border-width: 0 0 0 10px;
}
.alice-carousel__play-btn-item.__pause::after {
  top: 0;
  left: 18px;
}

.alice-carousel__dots {
  margin: 30px 3px 5px;
  padding: 0;
  list-style: none;
  text-align: center;
}
.alice-carousel__dots > li {
  display: inline-block;
}
.alice-carousel__dots-item:not(.__custom) {
  width: 8px;
  height: 8px;
  cursor: pointer;
  border-radius: 50%;
  background-color: #e0e4fb;
}
.alice-carousel__dots-item:not(.__custom):not(:last-child) {
  margin-right: 20px;
}
.alice-carousel__dots-item:not(.__custom):hover, .alice-carousel__dots-item:not(.__custom).__active {
  background-color: #6e7ebc;
}

.alice-carousel__slide-info {
  position: absolute;
  top: 20px;
  right: 20px;
  display: inline-block;
  padding: 5px 10px;
  color: #465798;
  border-radius: 5px;
  background-color: rgba(224, 228, 251, 0.6);
}
.alice-carousel__slide-info-item {
  vertical-align: middle;
  line-height: 0;
}
.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}
.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}
.react-grid-item img {
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;  
}
.react-grid-item.cssTransforms {
  transition-property: transform;
}
.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.dropping {
  visibility: hidden;
}

.react-grid-item.react-grid-placeholder {
  background: red;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
}

.react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}

.react-resizable-hide > .react-resizable-handle {
  display: none;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-w,
.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-n,
.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
  background-position: bottom right;
  padding: 0 3px 3px 0;
}
.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.react-resizable-handle-w,
.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}
.react-resizable-handle-n,
.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}
/* roboto-cyrillic-ext-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/_next/static/media/roboto-cyrillic-ext-300-normal.cd7c5715.woff2) format('woff2'), url(/_next/static/media/roboto-all-300-normal.39add8fb.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}
/* roboto-cyrillic-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/_next/static/media/roboto-cyrillic-300-normal.88798412.woff2) format('woff2'), url(/_next/static/media/roboto-all-300-normal.39add8fb.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}
/* roboto-greek-ext-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/_next/static/media/roboto-greek-ext-300-normal.bc5ce703.woff2) format('woff2'), url(/_next/static/media/roboto-all-300-normal.39add8fb.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}
/* roboto-greek-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/_next/static/media/roboto-greek-300-normal.25dc89b0.woff2) format('woff2'), url(/_next/static/media/roboto-all-300-normal.39add8fb.woff) format('woff');
  unicode-range: U+0370-03FF;
}
/* roboto-vietnamese-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/_next/static/media/roboto-vietnamese-300-normal.b3d3e960.woff2) format('woff2'), url(/_next/static/media/roboto-all-300-normal.39add8fb.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}
/* roboto-latin-ext-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/_next/static/media/roboto-latin-ext-300-normal.37d4965d.woff2) format('woff2'), url(/_next/static/media/roboto-all-300-normal.39add8fb.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}
/* roboto-latin-300-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url(/_next/static/media/roboto-latin-300-normal.a4eae32d.woff2) format('woff2'), url(/_next/static/media/roboto-all-300-normal.39add8fb.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* roboto-cyrillic-ext-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/roboto-cyrillic-ext-400-normal.d7827ae3.woff2) format('woff2'), url(/_next/static/media/roboto-all-400-normal.2e9e9400.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}
/* roboto-cyrillic-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/roboto-cyrillic-400-normal.2d9c9d60.woff2) format('woff2'), url(/_next/static/media/roboto-all-400-normal.2e9e9400.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}
/* roboto-greek-ext-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/roboto-greek-ext-400-normal.2b547ded.woff2) format('woff2'), url(/_next/static/media/roboto-all-400-normal.2e9e9400.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}
/* roboto-greek-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/roboto-greek-400-normal.63e6dc18.woff2) format('woff2'), url(/_next/static/media/roboto-all-400-normal.2e9e9400.woff) format('woff');
  unicode-range: U+0370-03FF;
}
/* roboto-vietnamese-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/roboto-vietnamese-400-normal.c95fc061.woff2) format('woff2'), url(/_next/static/media/roboto-all-400-normal.2e9e9400.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}
/* roboto-latin-ext-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/roboto-latin-ext-400-normal.21abc8c8.woff2) format('woff2'), url(/_next/static/media/roboto-all-400-normal.2e9e9400.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}
/* roboto-latin-400-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/roboto-latin-400-normal.f2894edc.woff2) format('woff2'), url(/_next/static/media/roboto-all-400-normal.2e9e9400.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* roboto-cyrillic-ext-500-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/_next/static/media/roboto-cyrillic-ext-500-normal.a1b5c90d.woff2) format('woff2'), url(/_next/static/media/roboto-all-500-normal.d96daa81.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}
/* roboto-cyrillic-500-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/_next/static/media/roboto-cyrillic-500-normal.aa68ea54.woff2) format('woff2'), url(/_next/static/media/roboto-all-500-normal.d96daa81.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}
/* roboto-greek-ext-500-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/_next/static/media/roboto-greek-ext-500-normal.7ea6cffa.woff2) format('woff2'), url(/_next/static/media/roboto-all-500-normal.d96daa81.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}
/* roboto-greek-500-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/_next/static/media/roboto-greek-500-normal.533b03d2.woff2) format('woff2'), url(/_next/static/media/roboto-all-500-normal.d96daa81.woff) format('woff');
  unicode-range: U+0370-03FF;
}
/* roboto-vietnamese-500-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/_next/static/media/roboto-vietnamese-500-normal.7f8c0554.woff2) format('woff2'), url(/_next/static/media/roboto-all-500-normal.d96daa81.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}
/* roboto-latin-ext-500-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/_next/static/media/roboto-latin-ext-500-normal.85ebfb55.woff2) format('woff2'), url(/_next/static/media/roboto-all-500-normal.d96daa81.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}
/* roboto-latin-500-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url(/_next/static/media/roboto-latin-500-normal.3170fd9a.woff2) format('woff2'), url(/_next/static/media/roboto-all-500-normal.d96daa81.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

/* roboto-cyrillic-ext-700-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/roboto-cyrillic-ext-700-normal.dd3651fb.woff2) format('woff2'), url(/_next/static/media/roboto-all-700-normal.ca3d0fdb.woff) format('woff');
  unicode-range: U+0460-052F,U+1C80-1C88,U+20B4,U+2DE0-2DFF,U+A640-A69F,U+FE2E-FE2F;
}
/* roboto-cyrillic-700-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/roboto-cyrillic-700-normal.258a358e.woff2) format('woff2'), url(/_next/static/media/roboto-all-700-normal.ca3d0fdb.woff) format('woff');
  unicode-range: U+0301,U+0400-045F,U+0490-0491,U+04B0-04B1,U+2116;
}
/* roboto-greek-ext-700-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/roboto-greek-ext-700-normal.a8d16efd.woff2) format('woff2'), url(/_next/static/media/roboto-all-700-normal.ca3d0fdb.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}
/* roboto-greek-700-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/roboto-greek-700-normal.432b858b.woff2) format('woff2'), url(/_next/static/media/roboto-all-700-normal.ca3d0fdb.woff) format('woff');
  unicode-range: U+0370-03FF;
}
/* roboto-vietnamese-700-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/roboto-vietnamese-700-normal.72bf832f.woff2) format('woff2'), url(/_next/static/media/roboto-all-700-normal.ca3d0fdb.woff) format('woff');
  unicode-range: U+0102-0103,U+0110-0111,U+0128-0129,U+0168-0169,U+01A0-01A1,U+01AF-01B0,U+1EA0-1EF9,U+20AB;
}
/* roboto-latin-ext-700-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/roboto-latin-ext-700-normal.6af98c24.woff2) format('woff2'), url(/_next/static/media/roboto-all-700-normal.ca3d0fdb.woff) format('woff');
  unicode-range: U+0100-024F,U+0259,U+1E00-1EFF,U+2020,U+20A0-20AB,U+20AD-20CF,U+2113,U+2C60-2C7F,U+A720-A7FF;
}
/* roboto-latin-700-normal*/
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/roboto-latin-700-normal.71b2beb8.woff2) format('woff2'), url(/_next/static/media/roboto-all-700-normal.ca3d0fdb.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

